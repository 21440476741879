import { generatePath } from 'react-router-dom';

// This type is mostly for allowing TS to shorten the type hints.
type PathGeneratorFor<Path extends string> = {
  template: Path;
  (params?: Parameters<typeof generatePath<Path>>[1]): string;
  createChildPath: <ChildPath extends string>(
    childPath: ChildPath,
  ) => PathGeneratorFor<`${Path}/${ChildPath}`>;
};

export const createPathGenerator = <Path extends string>(
  pathTemplate: Path,
): PathGeneratorFor<Path> => {
  const builder: PathGeneratorFor<Path> = (params) =>
    generatePath(pathTemplate, params);
  builder.template = pathTemplate;

  builder.createChildPath = (childPath) =>
    createPathGenerator(`${pathTemplate}/${childPath}`);

  return builder;
};
