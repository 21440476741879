import { type PropsWithChildren, useCallback, useMemo } from 'react';

import { useToast } from '@/components/ui/toast';

import * as errorLogger from '@/utils/error-logger';

import {
  ErrorHandlerContext,
  type HandleErrorUIMessage,
} from './error-handler-context';

const defaultTitle = 'Something went wrong.';
const defaultDescription = 'Please try again later.';

export const ErrorHandlerProvider = ({ children }: PropsWithChildren) => {
  const { toast } = useToast();

  // Log error with optional title and description, NO error toast
  const logError = useCallback(
    (error: unknown, message?: HandleErrorUIMessage) => {
      if (!message) return errorLogger.logError(error);

      return errorLogger.logError(error, {
        extra: {
          message,
        },
      });
    },
    [],
  );

  // Show error toast with title and description
  const showError = useCallback(
    (message?: HandleErrorUIMessage, errorId?: string) => {
      const descriptionText = message?.description || defaultDescription;

      return toast({
        title: message?.title ?? defaultTitle,
        description: errorId ? (
          <>
            {descriptionText}
            <br />
            Error ID {errorId}.
          </>
        ) : (
          descriptionText
        ),
        variant: 'destructive',
        duration: errorId ? 30000 : 5000, // show error toast for a longer time when there is error ID so that it can be copied/screen-shot
      });
    },
    [toast],
  );

  // Log error AND show error toast with title and description
  const handleError = useCallback(
    (error: unknown, message?: HandleErrorUIMessage) => {
      const errorId = logError(error, message);
      showError(message, errorId);
      return errorId;
    },
    [logError, showError],
  );

  const context = useMemo(
    () => ({ handleError, logError, showError, errorLogger }),
    [handleError, logError, showError],
  );

  return (
    <ErrorHandlerContext.Provider value={context}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};
