import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { logError } from '@/utils/error-logger';

import { ErrorMessage } from './error-message';

export const RouterErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => {
    logError(error);
  }, [error]);

  return <ErrorMessage />;
};
