import { createPathGenerator } from './utils';

export const pathToProposals = createPathGenerator('/proposals');
export const pathToPrintProposal = pathToProposals.createChildPath('print');

export const pathToNewProposal = pathToProposals.createChildPath('new');
export const pathToViewProposal =
  pathToProposals.createChildPath(':proposalId');
export const pathToEditProposal = pathToViewProposal.createChildPath('edit');
export const pathToReviewProposal =
  pathToViewProposal.createChildPath('review');
export const pathToProposalEmailComposer =
  pathToViewProposal.createChildPath('send');
export const pathToProposalTeaser =
  pathToProposals.createChildPath(':proposalId/teaser');
