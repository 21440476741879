import { type PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  condition: boolean;
  wrapper: ({ children }: PropsWithChildren) => JSX.Element;
}>;

export const ConditionalWrapper = ({
  condition,
  wrapper: Wrapper,
  children,
}: Props) => {
  return condition ? <Wrapper>{children}</Wrapper> : children;
};
