import { type LucideProps } from 'lucide-react';
import { forwardRef } from 'react';

export const MenuCollapse = forwardRef<SVGSVGElement, LucideProps>(
  (props, ref) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      fill='currentColor'
      viewBox='0 0 20 20'
      ref={ref}
      {...props}
    >
      <path d='m9.1666 4.1668c-0.25569 0-0.83334 0.37309-0.83334 0.83333s0.57765 0.83333 0.83334 0.83333h6.6667c0.2557 0 0.8333-0.37309 0.8333-0.83333s-0.5776-0.83333-0.8333-0.83333h-6.6667z' />
      <path d='m9.1666 9.1668c-0.25569 0-0.83334 0.37309-0.83334 0.83335 0 0.4602 0.57765 0.8333 0.83334 0.8333h6.6667c0.2557 0 0.8333-0.3731 0.8333-0.8333 0-0.46026-0.5776-0.83335-0.8333-0.83335h-6.6667z' />
      <path d='m9.1666 14.167c-0.25569 0-0.83334 0.3731-0.83334 0.8334 0 0.4602 0.57765 0.8333 0.83334 0.8333h6.6667c0.2557 0 0.8333-0.3731 0.8333-0.8333 0-0.4603-0.5776-0.8334-0.8333-0.8334h-6.6667z' />
      <path d='m5.5892 8.0893c0.32544-0.32544 0.32544-0.85308 0-1.1785-0.32543-0.32544-0.85307-0.32544-1.1785 0l-2.5 2.5c-0.32543 0.32543-0.32543 0.85307 0 1.1785l2.5 2.5c0.32544 0.3255 0.85308 0.3255 1.1785 0 0.32544-0.3254 0.32544-0.853 0-1.1785l-1.9107-1.9107 1.9107-1.9108z' />
    </svg>
  ),
);
