import {
  type ReactElement,
  type PropsWithChildren,
  type ErrorInfo,
  PureComponent,
} from 'react';

import { logError } from '@/utils/error-logger';

import { ErrorMessage } from './error-message';

type Props = PropsWithChildren<{
  fallback?: ReactElement;
}>;

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(error, { extra: { errorInfo } });
  }

  render() {
    const { fallback, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      if (fallback) {
        return fallback;
      }
      return <ErrorMessage />;
    }

    return children;
  }
}
