import { pathToTechReport } from './technician';

export const pathToTechReportAssets =
  pathToTechReport.createChildPath('assets');
export const pathToTechReportAssetsNew =
  pathToTechReportAssets.createChildPath('new');
export const pathToTechReportAssetsSearch =
  pathToTechReportAssets.createChildPath('search');
export const pathToTechReportAsset =
  pathToTechReportAssets.createChildPath(':assetId');

export const pathToTechControlPanelRoot =
  pathToTechReportAssets.createChildPath('control-panel');
export const pathToTechControlPanelNew =
  pathToTechControlPanelRoot.createChildPath('new');
export const pathToTechControlPanel =
  pathToTechControlPanelRoot.createChildPath(':controlPanelId');

export const pathToTechReportAssetsNewEntryMenu =
  pathToTechReportAssets.createChildPath('new-menu');

const pathToTechControlPanelAssetsRoot =
  pathToTechControlPanel.createChildPath('assets');
export const pathToTechControlPanelAssetsSearch =
  pathToTechControlPanelAssetsRoot.createChildPath('search');
export const pathToTechControlPanelAssetsNew =
  pathToTechControlPanelAssetsRoot.createChildPath('new');

export const pathToTechZoneOrLoopRoot =
  pathToTechControlPanelAssetsRoot.createChildPath('zone-loop');
export const pathToTechZoneOrLoop =
  pathToTechZoneOrLoopRoot.createChildPath(':zoneOrLoopId');
export const pathToTechZoneOrLoopAssetsNew =
  pathToTechZoneOrLoop.createChildPath('assets/new');
export const pathToTechZoneOrLoopSearch =
  pathToTechZoneOrLoop.createChildPath('search');
export const pathToTechZoneOrLoopFloors =
  pathToTechZoneOrLoop.createChildPath('floors');
export const pathToTechZoneOrLoopFloorsAssetsNew =
  pathToTechZoneOrLoopFloors.createChildPath('assets/new');

export const pathToTechValvesRoot =
  pathToTechReportAssets.createChildPath('valves');
export const pathToTechValveNew = pathToTechValvesRoot.createChildPath('new');
export const pathToTechValve = pathToTechValvesRoot.createChildPath(':valveId');
export const pathToTechReportValvesSearch =
  pathToTechValvesRoot.createChildPath('search');

export const pathToTechFireExtsRoot =
  pathToTechReportAssets.createChildPath('fire-extinguishers');
export const pathToTechFireExtNew =
  pathToTechFireExtsRoot.createChildPath('new');
export const pathToTechFireExt =
  pathToTechFireExtsRoot.createChildPath(':fireExtId');
export const pathToTechReportFireExtSearch =
  pathToTechFireExtsRoot.createChildPath('search');

export const pathToTechSmokeDampersRoot =
  pathToTechReportAssets.createChildPath('smoke-dampers');
export const pathToTechSmokeDamperNew =
  pathToTechSmokeDampersRoot.createChildPath('new');
export const pathToTechSmokeDamper =
  pathToTechSmokeDampersRoot.createChildPath(':smokeDamperId');
export const pathToTechReportSmokeDamperSearch =
  pathToTechSmokeDampersRoot.createChildPath('search');
