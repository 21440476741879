import { type NonIndexRouteObject, Outlet } from 'react-router-dom';

import { ErrorHandlerProvider } from '@/components/errors';

import { ConditionalWrapper } from '@/utils/conditional-wrapper';

type Args = {
  withOfflineExchange?: boolean;
  withRxDb?: boolean;
};

export const appProviders =
  ({
    withOfflineExchange = false,
    withRxDb = false,
  }: Args): NonNullable<NonIndexRouteObject['lazy']> =>
  async () => {
    // @TODO I think these promises are resolved every time
    const { Layout } = await import('@/components/ui/layout');
    const { RequireAuth } = await import('@/features/auth');
    const { APIProvider } = await import('@vis.gl/react-google-maps');
    const { NavigationPanelProvider } = await import('@/components/ui/layout');
    const { InlineToastProvider } = await import(
      '@/components/ui/inline-toast'
    );
    const { AuthProvider } = await import('@/features/auth');
    const { GraphQLClientProvider } = await import('@/features/graphql-client');
    const { UserProvider } = await import('@/features/user-provider');
    const { RxDbProvider } = await import('@/utils/rxdb');
    const { PosthogProviderWrapper } = await import('@/utils/posthog');
    const { RxDBSyncStatusTracker } = await import(
      '@/utils/rxdb/rxdb-sync-status-tracker'
    );
    const { StatsTracker } = await import('@/utils/stats-tracker');
    const { OnlineStatusTracker } = await import(
      '@/utils/online-status-tracker'
    );

    return {
      Component: () => (
        <ErrorHandlerProvider>
          <PosthogProviderWrapper>
            <StatsTracker />
            <AuthProvider>
              <ConditionalWrapper condition={withRxDb} wrapper={RxDbProvider}>
                <GraphQLClientProvider
                  withOfflineExchange={withOfflineExchange}
                >
                  <UserProvider>
                    <InlineToastProvider>
                      <NavigationPanelProvider>
                        <RequireAuth>
                          <APIProvider
                            apiKey={import.meta.env.GOOGLE_MAPS_API_KEY}
                          >
                            {withRxDb && <RxDBSyncStatusTracker />}
                            <OnlineStatusTracker />
                            <Layout />
                          </APIProvider>
                        </RequireAuth>
                      </NavigationPanelProvider>
                    </InlineToastProvider>
                  </UserProvider>
                </GraphQLClientProvider>
              </ConditionalWrapper>
            </AuthProvider>
          </PosthogProviderWrapper>
        </ErrorHandlerProvider>
      ),
    };
  };

export const landingProviders =
  (): NonNullable<NonIndexRouteObject['lazy']> => async () => {
    const { AuthProvider } = await import('@/features/auth');
    const { GraphQLClientProvider } = await import('@/features/graphql-client');
    const { UserProvider } = await import('@/features/user-provider');
    const { PosthogProviderWrapper } = await import('@/utils/posthog');
    const { StatsTracker } = await import('@/utils/stats-tracker');

    return {
      Component: () => (
        <ErrorHandlerProvider>
          <PosthogProviderWrapper>
            <StatsTracker />
            <AuthProvider>
              <GraphQLClientProvider>
                <UserProvider>
                  <Outlet />
                </UserProvider>
              </GraphQLClientProvider>
            </AuthProvider>
          </PosthogProviderWrapper>
        </ErrorHandlerProvider>
      ),
    };
  };
