import * as Sentry from '@sentry/react';

export const errorLoggerInstance = Sentry;

export const logError: typeof Sentry.captureException = (...args) => {
  // eslint-disable-next-line no-console
  console.error(args[0]); // captureException doesn't log to console, so we do it manually

  return Sentry.captureException(...args);
};

export const setErrorLoggerContext: typeof Sentry.setContext = (...args) => {
  return Sentry.setContext(...args);
};

export const setErrorLoggerUser: typeof Sentry.setUser = (...args) => {
  return Sentry.setUser(...args);
};

export const addBreadcrumb: typeof Sentry.addBreadcrumb = (...args) => {
  return Sentry.addBreadcrumb(...args);
};
