import { createContext } from 'react';

import type * as errorLogger from '@/utils/error-logger';

export const ErrorHandlerContext = createContext<
  ErrorHandlerContextValues | undefined
>(undefined);

export type HandleErrorUIMessage = {
  title?: string;
  description?: string;
};

export type ErrorHandlerContextValues = {
  handleError: (
    error: unknown,
    { title, description }?: HandleErrorUIMessage,
  ) => void;
  logError: (
    error: unknown,
    { title, description }?: HandleErrorUIMessage,
  ) => void;
  showError: (
    { title, description }?: HandleErrorUIMessage,
    id?: string,
  ) => void;
  errorLogger: typeof errorLogger;
};
