// https://docs.sentry.io/security-legal-pii/scrubbing/server-side-scrubbing/
const scrubbedSubstringMap = {
  password: 'pazzword',
  secret: 'secred',
  passwd: 'pazzwd',
  api_key: 'abi_kay',
  apikey: 'abikay',
  access_token: 'access_tokken',
  auth: 'auz',
  credentials: 'credentz',
  stripetoken: 'stripetokken',
  token: 'tokken',
  privatekey: 'privatekay',
  private_key: 'private_kay',
};

// Use this function to change substrings of strings so that they are not scrubbed by Sentry
// Useful for innocent substrings that are scrubbed by default
//   - for example, authorisation framework event names
export const unscrub = (value: string) =>
  Object.entries(scrubbedSubstringMap).reduce(
    (acc, [scrubbedSubstring, replacementSubstring]) =>
      acc.replaceAll(scrubbedSubstring, replacementSubstring),
    value,
  );
