import { useEffect, useRef } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';

import { useToast, ToastAction } from '@/components/ui/toast';

const CHECK_FOR_UPDATE_INTERVAL = 1000 * 60 * 30;
const AUTO_RELOAD_IF_TIME_SINCE_FIRST_RENDER_LESS_THAN = 3000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logInProduction = (...args: any[]) => {
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.log('SW:', ...args);
  }
};

/**
 * This component shows a toast when a new version of the application is available.
 * How it works
 * - When application is loaded and new version has NOT been published:
 *   - `useRegisterSW` sets `needRefresh=false`, nothing happens
 * - When application is loaded and new version has been published:
 *   - `useRegisterSW` sets `needRefresh=true`
 *   - if new version has been detected fast:
 *     - app is reloaded without asking user
 *   - if new version has been detected later:
 *     - a toast is shown to user and is not automatically dismissed
 *     - if user clicks refresh button, page is reloaded with new version of app
 *     - if user dismisses the toast, it is shown again when app is loaded next time
 * - While application is running:
 *   - `setInterval` is used to check for updates
 *   - if new version is available, `useRegisterSW` sets `needRefresh=true`
 *   - toast logic works the same as in application load case
 *   - if user dismisses the toast:
 *     - it is shown again when app is loaded next time
 *     - it is not shown one more time when app receives one more update
 *
 * How to test
 * When using dev server, SW experience can be confusing as there is also Hot Module Replacement involved
 * To test, one can use `pnpm build && pnpm preview`
 */

// see https://vite-pwa-org.netlify.app/frameworks/react.html
export const ReloadPrompt = () => {
  const firstRenderTimeRef = useRef(Date.now());
  const { toast } = useToast();
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swURL, swRegistration) {
      logInProduction('onRegistered', swRegistration);

      if (!swRegistration) return;

      logInProduction('Starting periodic update', swRegistration);

      // This part checks for app updates which might be published while app is open
      //   and triggers hook update (`needRefresh` change to true)
      // See https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html
      setInterval(async () => {
        logInProduction('Check for update');

        if (!(!swRegistration.installing && navigator)) return;

        if ('connection' in navigator && !navigator.onLine) return;

        logInProduction('Check for update: fetching');

        const resp = await fetch(swURL, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache',
          },
        });

        logInProduction('Check for update: response', resp);

        if (resp?.status === 200) await swRegistration.update();
      }, CHECK_FOR_UPDATE_INTERVAL);
    },
    onRegisterError(error) {
      throw new Error('ReloadPrompt: ServiceWorker registration error', {
        cause: error,
      });
    },
    onNeedRefresh() {
      logInProduction('onNeedRefresh');
    },
    onOfflineReady() {
      logInProduction('Ready to work offline');
    },
  });

  logInProduction({ needRefresh, offlineReady });

  useEffect(() => {
    if (needRefresh) {
      const ignoreRefreshRequest = () => {
        setOfflineReady(false);
        setNeedRefresh(false);
      };

      const firstRenderTime = firstRenderTimeRef.current;
      const timeSinceRender = Date.now() - firstRenderTime;
      if (timeSinceRender < AUTO_RELOAD_IF_TIME_SINCE_FIRST_RENDER_LESS_THAN) {
        // Reload app without asking if it has been loaded recently
        //   as it is safe (no forms are being filled etc)
        logInProduction('Reloading app without asking');
        updateServiceWorker();
      } else {
        // Running this from `onNeedRefresh` could be nice
        //   but it doesn't have access to `updateServiceWorker`
        toast({
          title: 'App update needed',
          description: 'There is a new application version available.',
          action: (
            <ToastAction
              altText='Refresh'
              onClick={() => updateServiceWorker()}
            >
              Update
            </ToastAction>
          ),
          onOpenChange: (isOpen) => {
            if (!isOpen) ignoreRefreshRequest();
          },
          duration: Infinity, // show until user closes the toast
        });
      }
    }
  }, [
    needRefresh,
    setOfflineReady,
    setNeedRefresh,
    toast,
    updateServiceWorker,
  ]);

  // This component just shows a toast, no render.
  return null;
};
