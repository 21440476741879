export const ErrorMessage = () => (
  <div className='my-10 flex h-full'>
    <div className='m-auto flex flex-col items-center justify-center space-y-4'>
      <p>Something went wrong</p>
      <a
        href='/'
        className='m-auto block bg-button-bgPrimary-default px-4 py-2 text-button-fgPrimary-default hover:bg-button-bgPrimary-hovered hover:text-button-fgPrimary-hovered active:bg-button-bgPrimary-pressed active:text-button-fgPrimary-pressed'
      >
        Go to the start
      </a>
    </div>
  </div>
);
