import { cva, type VariantProps } from 'class-variance-authority';
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  type ReactElement,
  forwardRef,
} from 'react';

import { Action, Close, Provider, Root, Viewport } from '@radix-ui/react-toast';

import { CloseMd, CheckCircle2, XCircle } from '@/components/ui/icon';

import { cn } from '@/utils';

export const ToastProvider = Provider;

type ToastViewportProps = Omit<
  ComponentPropsWithoutRef<typeof Viewport>,
  'className'
>;

export const ToastViewport = forwardRef<
  ElementRef<typeof Viewport>,
  ToastViewportProps
>((props, ref) => (
  <Viewport
    ref={ref}
    className={cn(
      'fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px] print:hidden',
    )}
    {...props}
  />
));

ToastViewport.displayName = Viewport.displayName;

/* eslint tailwindcss/no-custom-classname: ['error', { whitelist: ['destructive'] }] */
const toastVariants = cva(
  'group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
  {
    variants: {
      variant: {
        success: 'border bg-surface-primary text-content-primary',
        destructive:
          'destructive group border-border-critical bg-surface-critical text-content-inversePrimary',
      },
    },
    defaultVariants: {
      variant: 'success',
    },
  },
);

type ToastCompProps = Omit<
  ComponentPropsWithoutRef<typeof Root> & VariantProps<typeof toastVariants>,
  'className'
>;

export const Toast = forwardRef<ElementRef<typeof Root>, ToastCompProps>(
  ({ variant, children, ...props }, ref) => (
    <Root ref={ref} className={cn(toastVariants({ variant }))} {...props}>
      {children}
    </Root>
  ),
);

Toast.displayName = Root.displayName;

type ToastActionProps = Omit<
  ComponentPropsWithoutRef<typeof Action>,
  'className'
>;

export const ToastAction = forwardRef<
  ElementRef<typeof Action>,
  ToastActionProps
>((props, ref) => (
  <Action
    ref={ref}
    className={cn(
      'inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium ring-offset-surface-primary transition-colors hover:bg-surface-quaternary focus:outline-none focus:ring-2 focus:ring-border-inputFocus focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-border-secondary/40 group-[.destructive]:hover:bg-surface-critical group-[.destructive]:hover:text-content-inversePrimary group-[.destructive]:focus:ring-border-criticalFocus',
    )}
    {...props}
  />
));

ToastAction.displayName = Action.displayName;

type ToastCloseProps = Omit<
  ComponentPropsWithoutRef<typeof Close>,
  'className'
>;

export const ToastClose = forwardRef<ElementRef<typeof Close>, ToastCloseProps>(
  (props, ref) => (
    <Close
      ref={ref}
      className={cn(
        'absolute right-2 top-2 rounded-md p-1 text-content-primary/50 opacity-0 transition-opacity hover:text-content-primary focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600',
      )}
      toast-close=''
      {...props}
    >
      <CloseMd className='size-4' />
    </Close>
  ),
);

ToastClose.displayName = Close.displayName;

type ToastIconProps = VariantProps<typeof toastVariants>;

export const ToastIcon = ({ variant }: ToastIconProps) => {
  if (variant === 'destructive') {
    return <XCircle className='size-5 text-content-error' />;
  }

  return <CheckCircle2 className='size-5 text-content-success' />;
};

export type ToastProps = ComponentPropsWithoutRef<typeof Toast>;

export type ToastActionElement = ReactElement<typeof ToastAction>;
