import { type SVGProps } from 'react';
import * as coolicons from 'react-coolicons';

type Coolicons = typeof coolicons;

// This breaks tree-shaking, but it's cheaper than writing
// a custom build script to do almost the same thing `react-coolicons` does.
const viewboxedCoolicons = Object.fromEntries(
  Object.entries(coolicons).map(([cooliconName, Coolicon]) => [
    cooliconName,
    {
      [cooliconName]: (props: SVGProps<SVGSVGElement>) => (
        <Coolicon viewBox='0 0 24 24' {...props} />
      ),
    }[cooliconName],
  ]),
) as Coolicons;

export default viewboxedCoolicons;
