import { createPathGenerator } from './utils';

export const pathToSettings = createPathGenerator('/settings');

export const pathToSettingsBusinessInfo =
  pathToSettings.createChildPath('business-info');

export const pathToSettingsProposalTemplate =
  pathToSettings.createChildPath('proposal-templates');

export const pathToSettingsIntegrations =
  pathToSettings.createChildPath('integrations');

export const pathToSettingsFormBuilder =
  pathToSettings.createChildPath('form-builder');
