import { Heading } from '@/components/ui/heading';
import { Text } from '@/components/ui/text';

import {
  Toast,
  ToastClose,
  ToastProvider,
  ToastViewport,
  ToastIcon,
} from './toast';
import { useToast } from './use-toast';

export const Toaster = () => {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, variant, ...props }) => (
        <Toast key={id} variant={variant} {...props}>
          <div className='flex flex-row items-center'>
            <div className='mr-2 shrink-0'>
              <ToastIcon variant={variant} />
            </div>
            <div className='grid gap-1'>
              {title && (
                <Heading as='h3' size='xs'>
                  {title}
                </Heading>
              )}
              {description && (
                <Text as='span' size='sm'>
                  {description}
                </Text>
              )}
            </div>
          </div>
          {action}
          <ToastClose />
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  );
};
